import React from "react";
import Layout from "../components/layout/Layout";
import { praticheAuto } from "../data/pratiche-auto/pratiche-auto";
import ListFeature from "../components/ListFeature";
import ContactSection from "../components/home-page/ContactSection";

const PraticheAuto = () => {
  return (
    <Layout>
      <ListFeature list={praticheAuto} />
      <ContactSection />
    </Layout>
  );
};

export default PraticheAuto;
