import { faMotorcycle, faCarSide, faTruck, faPeopleArrows, faPeopleCarryBox, faTruckArrowRight, faPassport, faIdCard, faHourglassStart, faVialCircleCheck, faNetworkWired } from '@fortawesome/free-solid-svg-icons';
export const praticheAuto = {
    title: "Pratiche auto",
    subtitle: "Presso l'Autoscuola Faro a Pisa è possibile usufruire del servizio delle seguenti pratiche auto:",
    list: [
        {
            id: "pratAuto1",
            title: "Passaggio di Proprietà",
            link: "/pratiche-auto/passaggio-di-proprieta",
            icon: faPeopleArrows,
        },
        {
            id: "pratAuto2",
            title: "Eredità veicolo",
            link: "/pratiche-auto/eredita-veicolo",
            icon: faPeopleCarryBox,
        },
        {
            id: "pratAuto3",
            title: "Esportazione veicolo",
            link: "/pratiche-auto/esportazione-veicolo",
            icon: faPassport,
        },
        {
            id: "pratAuto4",
            title: "Reimmatricolazione veicolo",
            link: "/pratiche-auto/reimmatricolazione-veicolo",
            icon: faTruckArrowRight,
        },
        {
            id: "pratAuto5",
            title: "Duplicato DU o carta di circolazione",
            link: "/pratiche-auto/duplicato-du",
            icon: faIdCard,
        },
        {
            id: "pratAuto6",
            title: "Intestazione temporanea",
            link: "/pratiche-auto/intestazione-temporanea",
            icon: faHourglassStart,
        },
        {
            id: "pratAuto7",
            title: "Collaudi e revisioni",
            link: "/pratiche-auto/collaudi-revisioni",
            icon: faVialCircleCheck,
        },
        {
            id: "pratAuto8",
            title: "Visura PRA",
            link: "/pratiche-auto/visura-pra",
            icon: faNetworkWired,
        },
    ],
    buttonLink: null,
    buttonText: null,
}